<template>
  <div class="width_1434 bg_white">
    <div>
      <h5 class="title">{{ news.contestNewsName }}</h5>
      <div v-html="news.context" class="singup_center"></div>
      <!-- <p class="time_singup">活动开始时间：{{ news.createTime }}</p>
      <p class="time_singup">活动结束时间：{{ news.updateTime }}</p> -->
    </div>
    <div style="height: 48px"></div>
    <div v-if="!isSignUp">
      <div class="massge">报名信息填写</div>
      <el-form :model="form">
        <div class="display-flex ">
          <div>
            <el-form-item label="园所名称" :label-width="formLabelWidth">
              <el-input
                v-model="name"
                autocomplete="off"
                disabled
                class="inputbox"
              ></el-input>
            </el-form-item>
            <br />
            <div style="height:27px;"></div>
            <el-form-item label="园所简称" :label-width="formLabelWidth">
              <el-input
                v-model="form.schoolSimName"
                autocomplete="off"
                placeholder="请输入简称，长度不超过4个字"
                :maxlength="4"
                class="inputbox"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <label>园所logo:</label>
            <el-upload
              list-type="picture-card"
              :auto-upload="true"
              :headers="{ token: $store.state.userInfo.token }"
              :action="constant.UPLOAD_URL + '/file/upload'"
              :on-success="onSchoolLogoUploaded"
              :on-exceed="schoolLogoExceed"
              :limit="1"
              :file-list="fileList"
              class="topPlus"
              v-if="!form.logo"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <img v-else :src="constant.URL+form.logo" alt="" width="50" height="50" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-else>
      <div class="massge">报名信息</div>
      <div class="display-flex">
        <div class="mages_number">
          <div>
            园所名称:
            <p>{{ name }}</p>
          </div>

          <div>
            园所简称:
            <p>{{ form.schoolSimName }}</p>
          </div>
        </div>
        <div class="mages_number">
          <p>园所LOGO:<img :src="url" alt="" /></p>
        </div>
      </div>
    </div>
    <div class="dialogfooter">
      <div type="primary" @click="dialogFormVisible" v-if="!isSignUp">
        提交报名
      </div>
      <div v-else class="yibaoming">已报名</div>
    </div>
    <div style="height: 20px"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      form: {
        //name: this.$store.state.schoolInfo?this.$store.state.schoolInfo.atteSchool.schoolName:'',
        //schoolId: this.$store.state.schoolInfo.atteSchool.id,
        // areaId:this.$store.state.spotrsindex,

        schoolId: "",
        areaId: "",
      },
      formLabelWidth: "68",
      fileList: [],
      isSignUp: "",
      news: {},
      url: "",
    };
  },
  watch: {
    "$store.state.schoolInfo.atteSchool.id"(newvalue) {
      if (newvalue) {
        this.form.schoolId = this.$store.state.schoolInfo.atteSchool.id;
        this.name = this.$store.state.schoolInfo.atteSchool.schoolName;
      }
    },
    "$store.state.spotrsindex"(newV){
      if(newV){
        this.form.areaId = this.$store.state.spotrsindex;
        this.contestSchoolGet();
      }
    }
  },
  mounted() {
    console.log("this.$store.state.spotrsindex");
    console.log(this.$store.state.spotrsindex);
    if(this.$store.state.spotrsindex){
      this.form.areaId = this.$store.state.spotrsindex;
      this.contestSchoolGet();
    }
    if (this.$store.state.userInfo.token) {
      this.name = this.$store.state.schoolInfo.atteSchool.schoolName;
      this.form = {
        schoolId: this.$store.state.schoolInfo.atteSchool.id,
        areaId:this.$store.state.spotrsindex,
        logo:this.$store.state.schoolInfo.atteSchool.schoolLogo || ''
      };
    }
  },
  methods: {
    contestSchoolGet() {
      //判断是否报名
      // if(!!this.params.contestAreaId) return;"

      var that = this;
      let data = {
        areaId: Number(that.$store.state.spotrsindex),
        schoolId:
          JSON.stringify(that.$store.state.schoolInfo) === "{}"
            ? null
            : that.$store.state.schoolInfo.atteSchool.id,
        contest_new_type:5,
      };
      that.api.sports.contestSchoolGet(data).then((res) => {
        if (res.code == 20000) {
          that.news = res.data.news;
          that.isSignUp = res.data.isEnroll;
          if (that.isSignUp) {
            that.form.schoolSimName = res.data.schoolSimplifyName;
            that.name = this.$store.state.schoolInfo.atteSchool.schoolName;
            that.url = this.constant.URL + res.data.schoolLogoImage;
          }
        } else {
          that
            .$notice({
              message: res.message,
            })
            .isShow();
        }
      });
    },
    onSchoolLogoUploaded(res) {
      if (res.code == "20012") {
        this.$notice({
          message: "登陆失效",
        }).isShow();

        this.$store.commit("setLoginState", ["login", "/sport"]);
        return;
      }

      this.form.logo = res.data.filename;
    },
    //学校logo文件超出个数限制
    schoolLogoExceed() {
      this.$notice({
        message: "只能上传一张图片",
      }).isShow();
    },
    dialogFormVisible() {
      var that = this;
      if (!that.$store.state.userInfo.token) {
        that.$store.commit("setLoginState", "login");
        return;
      }
      if (that.$store.state.userInfo.teacherId) {
        that
          .$notice({
            message: "请用园长账号报名",
          })
          .isShow();
        return;
      }

      if (!that.form.schoolSimName) {
        that
          .$notice({
            message: "请填写园所简称",
          })
          .isShow();
        return;
      }
      if (!that.form.logo) {
        that
          .$notice({
            message: "请上传园所logo",
          })
          .isShow();
        return;
      }
      that.api.sports.schoolradd(that.form).then((e) => {
        console.log(e);
        if (e.code != "20000") {
          this.$notice({
            message: e.message,
          }).isShow();
          that.$router.push({
            path: "/sport",
          });
          return;
        }
        that.contestSchoolGet();
        this.$notice({
          type: "success",
          message: e.message,
        }).isShow();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.display-flex {
  
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.width_1434 {
  width: 1434px;
  margin: 0 auto;
  box-sizing: border-box;
  padding:0 314px;
}
.dialogfooter {
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
  

  div {
    display: inline-block;
    width: 232px;
    height: 56px;
    background: url("../../../src/assets/img/sports/Btn_Bg_TJBM.png") no-repeat
      left center;
    text-align: center;
    cursor: pointer;
    background-size: cover;
    color: white;
    font-size: 18px;
    line-height: 56px;
  }
  .yibaoming {
    background-image: url("../../assets/img/sports/Btn_Bg_YBM.png");
  }
}
.dialogfooter {
  div:hover {
    background-image: url("../../assets/img/sports/Btn_Bg_TJBM_H.png");
  }
}
.inputbox {
  width: 340px;
}
.bg_white {
  background: white;
}

.el-form-item {
  display: inline-block !important;
  margin-left: 20px !important;
}
.topPlus {
  display: inline-block;
  font-size: 30px;
  line-height: 40px;
}
.title {
  font-size: 28px !important;

  font-weight: bold !important;
  text-align: center;
  color: #333333;
  padding-top: 40px !important;
}
.singup_center {
  padding: 20px !important;
  line-height: 40px;
  font-size: 18px;
  text-indent: 2em;
  color: #333333;
}
.time_singup {
  padding: 0 20px !important;
  line-height: 40px;
  font-size: 20px;
}
.massge {
  font-size: 26px;
  color: #cb1b31;
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}
.mages_number {
  font-size: 16px;
  color: #888888;
  div {
    margin-bottom: 27px;
  }
  img {
    vertical-align: top;
    width: 80px;
    height: 80px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
  p {
    display: inline-block;
    color: #333333;
  }
}
</style>
<style>
.el-form-item__content {
  display: inline-block;
}
.el-upload {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 50px !important;
  height: 50px !important;
}
</style>
